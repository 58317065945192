<template>
  <div>
    <landing-page-header></landing-page-header>
    <div>
      <div class="container mt-5">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="/home" tag="a">{{ $t('menu.home') }}</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page"><router-link to="/gallery" tag="a">{{ $t('menu.gallery') }}</router-link></li>
            <li v-if="album" class="breadcrumb-item active">{{album.title}} </li>
          </ol>
        </nav>
        <div class="row">
          <div class="col-12 pt-3">
            <h2 v-if="album" class="title pl-1">{{album.title}}</h2>
            <hr>
          </div>
        </div>
        <div class="text-primary" v-if="album">
            <i class="fe fe-calendar"></i>
            {{ moment(album.createdAt).locale('fr', localization).format('DD/MM/YYYY HH:mm') }}
          </div>
          <div class=" m-4 pr-3" v-if="album">
            {{album.desc}}
          </div>
        <div class="row" v-if="data && data.length == 0">
          <div class="col-12 pb-2">
            <a-empty
              :image="Empty.PRESENTED_IMAGE_SIMPLE"
              :image-style="{
                height: '60px',
              }"
            >
              <template #description>
                <span>
                  {{ $t('general.noData') }}
                </span>
              </template>
            </a-empty>
          </div>
        </div>
        <div v-if="data" class="row">
          <div class="col-12">
          <!-- all props & events -->
          <vue-easy-lightbox
            scrollDisabled
            escDisabled
            moveDisabled
            :visible="visible"
            :imgs="displayedData"
            :index="index"
            @hide="handleHide"
          ></vue-easy-lightbox>
          <div id="gallery">
            <div class="item " v-for="(image,index) in displayedData" :key="index" @click="show(index)"  :style="{ backgroundImage: 'url(' + image + ')' }">
              <a>
                <figure>
                  <img :src="image.src">
                  <figcaption></figcaption>
                </figure>
              </a>
            </div>
          </div>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-12">
            <div class="d-flex justify-content-center">
              <div class="spinner-grow text-primary" role="status"></div>
            </div>
          </div>
        </div>
        <div class="pagination">
          <a v-if="page != 1" @click="page = 1">«</a>
          <a v-if="page != 1" @click="page--">‹</a>
          <a
            v-for="pageNumber in pages.slice(page - 1, page + 5)"
            :class="{ selected: page == pageNumber }"
            :key="pageNumber"
            @click="page = pageNumber"
            >{{ pageNumber }}</a
          >
          <a v-if="page < pages.length" @click="page++">›</a>
          <a v-if="page < pages.length" @click="page = pages.length">»</a>
        </div>
      </div>
    </div>
    <div />
  </div>
</template>

<script>
/* eslint-disable */

import BookCard from '@/components/BookCard'
import apiAxios from '@/services/axios'
import LandingPageHeader from '@/components/LandingPageHeader'

import moment from 'moment'
import VueEasyLightbox from 'vue-easy-lightbox'
import { Empty } from 'ant-design-vue'

export default {
  name: 'Posts',
  components: { LandingPageHeader, BookCard, VueEasyLightbox },
  data: () => ({
    apiUrl: process.env.VUE_APP_API_URL,
    album: null,
    data: null,
    page: 1,
    perPage: 12,
    pages: [],
    index: 0,
    visible: false,
    Empty,
  }),
  computed: {
    displayedData() {
      return this.paginate(this.data)
    },
  },
  watch: {
    data() {
      this.setPages()
    },
  },
  created() {
    this.getData()
  },
  computed: {
    displayedData() {
      return this.paginate(this.data)
    },
  },
  methods: {
    moment,
    getData() {
      let id = this.$route.params.id;
      apiAxios
        .post('/gallery/filter', {
          query: { status: 'active', _id: id },
        })
        .then((res) => {
            this.album = res.data[0]
          let images = res.data[0].img;
          for(var i=0; i<images.length; i++ )
          {
            images[i].src = this.apiUrl + images[i].url
          }
          this.data = images;
        })
    },
    setPages() {
      let numberOfPages = Math.ceil(this.data.length / this.perPage)
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index)
      }
    },
    paginate(data) {
      let page = this.page
      let perPage = this.perPage
      let from = page * perPage - perPage
      let to = page * perPage
      return data.slice(from, to)
    },
    show(i) {
        this.index = i;
        this.visible = true
      },
    handleHide() {
        this.visible = false
      }
  },
}
</script>
<style lang="scss" scoped>
@import '@/mixins.scss';
hr{
  border-bottom: 2px solid $primary;
  width: 7em;
  margin: 0;
}

/* Pagination style */
@mixin transition-hover($x) {
  transition: all $x linear;
}

.pagination {
  text-align: center;
  padding: 20px 0;
  display: flex;
  justify-content: center;

  a {
    font-size: 1.08em;
    width: 50px;
    height: 50px;
    background-color: transparent;
    color: #222;
    border: 2px solid rgb(139, 139, 139);
    display: inline-block;
    vertical-align: middle;
    line-height: 45px;
    text-decoration: none;
    font-weight: 700;
    margin: 4px;

    @include transition-hover(0.2s);

    &:hover,
    &.selected {
      color: #fff;
      background-color: $primary;
      border-color: $primary;
    }
  }
}

#gallery{
  position:relative;
  width:80vw;
  height:100%;
  min-height:100%;
  transition: all ease 1s;
  background-color:#fff;
  overflow-x: hidden;
  overflow-y: hidden;
}
#gallery .item{
  width:25%;
  display:block;
  float: left;
  transition: all ease 400ms;
  background-color:#000;
  position: relative;
}
#gallery .item:hover,
#gallery .item:focus{
  transform:scale(1.1);
  z-index:50;
  box-shadow:0 0 10px black;}
#gallery .item figure{
  transition: all ease 1s;
  overflow:hidden;
  height:300px;
  padding:0;
  margin:0;
  position: relative;
}
#gallery .item figure img{
  width: 100%;
  height: 100%;
  transform: scale(1.4);
  transition: all ease 1s;
  object-fit: cover;
}
#gallery .item:hover figure,
#gallery .item:focus figure{
  transition: all ease 400ms;
}
#gallery .item:hover figure img,
#gallery .item:focus figure img{
  transform:scale(1.4);
  transition: all ease 1s;
}
#gallery.hover{
  transition: all ease 1s;
/*   opacity:.7; */
}
#gallery.hover .item{
  opacity:.9;
  filter: blur(1px);
}
#gallery.hover .item:hover,
#gallery.hover .item:focus{
  opacity:1;
  filter: blur(0);
}
#gallery .item figcaption{
  position:absolute;
  font-size:18px;
  color:#fff;
  width:100%;
  height:100%;
  padding:10%;
  display:flex;
  justify-content:center;
  align-items:center;
  text-align:center;
  top:0;
  left:0;
  transform:scale(1.3);
  filter: blur(4px);
  opacity:0;
  transition:500ms;
  background-color: rgba(133, 1, 1, 0.842);
  text-transform:uppercase;
  font-weight:bold;
}
#gallery .item:hover figcaption,
#gallery .item:focus figcaption{
  transform:scale(1);
  filter: blur(0);
  opacity:1;
  transition:300ms;}
@media (max-width:1368px){
  #gallery .item{width:25%;}
}
@media (max-width:1080px){
  #gallery .item{width:25%;}
}
@media (max-width:800px){
  #gallery .item{width:50%;}
  #gallery .item figure{
    height:250px;
  }
  #gallery .item figure img{
    transform:rotate(0) scale(1);
    transition: all ease 1s;
    width:auto;
    min-width:100%;
    height:100%;
    min-height:250px;
  }
  #gallery .item:hover figure img{
    transform:rotate(0) scale(1);
    
  }
}
@media (max-width:500px){
  #gallery .item{width:100%;}
  #gallery .item figure{
    height:250px;
  }

  .date {
  color: $primary;
}

.description{
  text-align: justify;
  text-justify: inter-word;
  margin: 20px;
}
}
</style>
